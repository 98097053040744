import React, { Fragment } from 'react'
import Facts from '../../components/Facts'
import Team from '../../components/Team'
import Testimonial from '../../components/Testimonial'
import { Four, One, Row, RowGroup, Three, Two } from '../../components/Grid'
import OtherProjects from '../../components/OtherProjects'
import ProjectsContext from '../../components/ProjectsContext'
import Image from '../../components/Image'
import Caption from '../../components/Caption'
import SEO from '../../components/SEO'
import { ProjectName } from '../../components/Style'
import ContactForm from '../../components/ContactForm'
import Layout from '../../components/Layout'

const breadcrumbs = `{
  "@context": "http://schema.org",
  "@type": "BreadcrumbList",
  "itemListElement": [{
    "@type": "ListItem",
    "position": 1,
    "item": {
      "@id": "https://500tech.com/projects",
      "name": "Projects"
    }
  },{
    "@type": "ListItem",
    "position": 2,
    "item": {
      "@id": "https://500tech.com/projects/unpakt",
      "name": "Unpakt"
    }
  }]
}`

export default () => {
  return (
    <Layout>
      <ProjectsContext.Consumer clientName="Unpakt">
        {({ allProjects, currentProject }) => (
          <Fragment>
            <SEO
              path="/projects/unpakt"
              title="Featured AngularJS project for Unpakt by 500Tech"
              description="We’ve built an AngularJS application in the moving industry for Unpakt."
              ld={breadcrumbs}
            />

            <ProjectName>{currentProject.projectName}</ProjectName>
            <Facts {...currentProject} />

            <RowGroup>
              <Row>
                <Three>
                  <p>
                    Unpakt helps people move by finding the best available
                    mover.
                  </p>

                  <p>
                    We helped Unpakt completely rebuild their move booking flow
                    as a single-page application using AngularJS. After
                    launching the new version, Unpakt saw increased booking and
                    lower bounce rates due to much faster and reliable
                    experience.
                  </p>
                </Three>
              </Row>

              <Row>
                <Four>
                  <Image path="projects/unpakt/unpakt-1.jpg" />
                </Four>
              </Row>
            </RowGroup>

            <Team team={currentProject.team} />

            <Testimonial />

            <Row>
              <Three>
                <Image path="projects/unpakt/unpakt-2.jpg" />
                <Caption>
                  We don’t wait for server responses to display changes, but
                  gracefully roll back if server returns an error
                </Caption>
              </Three>
              <One>
                People calculate precise moving cost by specifying the items
                they need to move. With optimistic updates, adding or removing
                of items happens immediately.
              </One>
            </Row>

            <Row>
              <Two>
                <Image innerShadow path="projects/unpakt/unpakt-3.jpg" />
                <Caption>The sample items are organised in categories</Caption>
              </Two>

              <Two>
                <Image innerShadow path="projects/unpakt/unpakt-4.jpg" />
                <Caption>
                  Special handling suggestions are displayed for specific items
                  based on configuration coming from the server
                </Caption>
              </Two>
            </Row>

            <Row>
              <One>
                People are able to move between the steps of the booking flow,
                i.e. get back to adding or removing of items after having chosen
                a mover. All the changes are saved in background, even if you
                are not registered.
              </One>
              <Three>
                <Image path="projects/unpakt/unpakt-5.jpg" />
                <Caption>
                  Compare movers and sort by price, delivery dates or reviews
                </Caption>
              </Three>
            </Row>

            <RowGroup>
              <Row>
                <Three>
                  Some details such as moving price or packing options are
                  calculated in background without blocking the interface or
                  transitions between steps.
                </Three>
              </Row>

              <Row>
                <Three>
                  <Image innerShadow path="projects/unpakt/unpakt-7.jpg" />
                </Three>
                <One>
                  When ready, people book their move using the third-party
                  payment system we’ve integrated into the booking page.
                </One>
              </Row>
            </RowGroup>

            <RowGroup>
              <Row>
                <Three>
                  All other static pages became a part of the single page
                  application for faster loading without full-page refreshes.
                </Three>
              </Row>
            </RowGroup>

            <OtherProjects currentProject={currentProject} />
            <ContactForm />
          </Fragment>
        )}
      </ProjectsContext.Consumer>
    </Layout>
  )
}
