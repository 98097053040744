import React from 'react'
import styled from 'react-emotion'
import { CONTRAST_COLOR, FONT_SMALL } from '../constants'

export default () =>
  null && (
    <Container>
      <Quote>
        The 500Tech were amazing. They built the app on time and the quality was
        great. Over 9000 farmers are already using this software.
      </Quote>

      <Author>John Smith, jFrog CTO</Author>
    </Container>
  )

const Container = styled.div`
  max-width: 720px;
  margin: 0 auto 60px;
  text-align: center;
`

const Quote = styled.div`
  color: ${CONTRAST_COLOR};
  font-size: 28px;
  line-height: 36px;
  margin-bottom: 8px;
`

const Author = styled.div`
  font: ${FONT_SMALL};
`
